<template>
  <div class="position-relative mb-3 w-100">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <div class="content-between">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
        </label>
      </div>

      <OtherLoading v-if="isEdit && isLoading" />
      <editor
        v-if="display"
        ref="editor"
        :value="value"
        :class="[{ error: isValidate }]"
        :api-key="$TinyMceKey"
        :init="init"
      />
      <div v-else class="card card-preview-editor p-3">
        <div class="edit-detail" @click="showEditor">
          <font-awesome-icon icon="pencil-alt" />
        </div>
        <div v-html="value"></div>
      </div>
    </div>

    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false"
        >Please fill out.</span
      >
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "text-editor",
  components: { editor: Editor, OtherLoading },
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      editContent: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
      init: {
        height: this.rows ? this.rows * 100 : 400,
        init_instance_callback: (editor) => {
          this.isLoading = false;
          editor.on("change", (e) => {
            this.$emit("onDataChange", editor.getContent());
          });
        },
        plugins: [
          "print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
        ],
        menubar: "file edit view insert format tools table help",
        toolbar:
          "bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        toolbar_mode: "sliding",
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        importcss_append: true,
        image_caption: true,
        font_formats:
          "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; DB HeaventRounded=DB HeaventRounded; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Kanit=kanit; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
        content_css: "/css/tinymcefont.css",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],

        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        image_class_list: [{ title: "Responsive", value: "img-responsive" }],
        content_style: "img {max-width: 100%;}",
        images_upload_handler: async (blobInfo, success, failure, progress) => {
          if (blobInfo.blob().size / 1024 / 1024 > 5) {
            failure("Maximun image size 5 MB");
            return;
          }
          var config = {
            onUploadProgress: (progressEvent) => {
              progress((progressEvent.loaded / progressEvent.total) * 100);
            },
          };
          var form = {
            Base64String: "data:image/png;base64," + blobInfo.base64(),
          };
          await this.$store.dispatch("ActionUploadImage", form);
          var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
          if (data.detail != "") {
            success(data.detail);
          } else {
            failure("Upload fail");
          }
        },
      },
      id: this.$route.params.id,
      isEdit: false,
      isLoading: true,
    };
  },
  watch: {
    // editContent(value) {
    //   return this.$emit("onDataChange", value);
    // },
    // value(val) {
    //   return (this.editContent = val);
    // },
  },
  computed: {
    display() {
      if (isNaN(this.id)) return true;
      return !this.id || this.id == 0 || this.isEdit;
    },
  },
  mounted() {},
  methods: {
    showEditor() {
      this.isEdit = true;
      this.$nextTick();
    },
    onDataChange(val) {
      this.$emit("onDataChange", val);
    },
  },
};
</script>

<style scoped>
.text-editor-custom {
  color: var(--font-main-color);
  font-size: 14px;
  margin-bottom: 2px;
}
/* .text-editor-custom.error {
  border: 2px solid red !important;
} */
.text-error {
  color: red;
}
::v-deep .error #quill-container {
  border-top: 1px;
  border-color: red;
}
.card-preview-editor {
  min-height: 350px;
  max-height: 400px;
  overflow: auto;
  position: relative;
  cursor: default;
}
.edit-detail {
  color: var(--primary-color);
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
}
</style>

const RichMenu = {
  path: "",
  name: "",
  redirect: "rich-menu",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "rich-menu",
      name: "Rich Menu",
      redirect: "rich-menu",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/RichMenu/panel.vue"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "list",
          name: "Rich Menu List",
          redirect: "/setting/rich-menu",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/views/pages/setting/settingPage/RichMenu/Index.vue"),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            {
              path: ":id",
              name: "Rich Menu Detail",
              redirect: "/setting/",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/RichMenu/Detail"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                // {
              ],
            },
            // {
          ],
        },

        {
          path: "check-binding-user",
          name: "Check Rich Menu",
          redirect: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/RichMenu/CheckUser.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
          ],
        },
      ],
    },
  ],
};

export default RichMenu;

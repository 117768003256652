const PersonalRoute = {
  path: "",
  name: "",
  redirect: "personal",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "personal",
      name: "Personal",
      redirect: "personal",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/PersonalForm/index.vue"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "form",
          name: "Personal Form Setting",
          redirect: "/setting/",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/PersonalForm/form.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
        {
          path: "form/email",
          name: "Personal Email Form Setting",
          redirect: "/setting/",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/PersonalForm/email.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
        {
          path: "setting/:id",
          name: "Field Setting",
          redirect: "/setting/personal/setting/:id",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/PersonalForm/masterData.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
        {
          path: "contactaddress",
          name: "Contact Address",
          redirect: "/setting/contactaddress",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/Address/ContactForm.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
      ],
    },
  ],
};
const CompanyRoute = {
  path: "",
  name: "",
  redirect: "company",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "company",
      name: "Company",
      redirect: "company",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/CompanyForm/index.vue"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "form",
          name: "Company Form Setting",
          redirect: "/setting/",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/CompanyForm/form.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
        {
          path: "setting/:id",
          name: "Company Setting Document",
          redirect: "/setting/personal/setting/:id",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/CompanyForm/masterData.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
        {
          path: "contactaddress",
          name: "Contact Address",
          redirect: "/setting/contactaddress",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/Address/ContactForm.vue"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
      ],
    },
  ],
};
export { PersonalRoute, CompanyRoute };

import { render, staticRenderFns } from "./UploadFile.vue?vue&type=template&id=7c7dd455&scoped=true"
import script from "./UploadFile.vue?vue&type=script&lang=js"
export * from "./UploadFile.vue?vue&type=script&lang=js"
import style0 from "./UploadFile.vue?vue&type=style&index=0&id=7c7dd455&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7dd455",
  null
  
)

export default component.exports